// Fonts
//@import "../css/font.css";

// Variables
@import 'system_variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import "~@coreui/coreui/scss/coreui";
@import '~@coreui/icons/css/all.min.css';

@import "~remixicon/fonts/remixicon.css";

@import '~font-awesome/css/font-awesome.min';
@import '~simple-line-icons/css/simple-line-icons';
@import "~notiflix/dist/notiflix-2.6.0.min.css";